import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

interface User {
  id: number;
  is_adm: number;
  email: string;
  nome: string;
  nascimento: Date;
  genero: string;
  phone1: string;
  phone2: null;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  confirm_password?: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  loading: boolean;
  currentStep: number;
  setStep(step: number): void;
  respId: number;
  setResp(value: number): void;
  propertyId: number;
  setProperty(value: number): void;
  managementId: number;
  setManagement(value: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [respId, setRespId] = useState(0);
  const [propertyId, setPropertyId] = useState(0);
  const [managementId, setManagementId] = useState(0);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@GetapAdm:token');
    const user = localStorage.getItem('@GetapAdm:user');
    const currentStep = localStorage.getItem('@GetapAdm:step');
    const propertyId = localStorage.getItem('@GetapAdm:property');
    const respId = localStorage.getItem('@GetapAdm:resp');
    const managementId = localStorage.getItem('@GetapAdm:management');

    currentStep ? setCurrentStep(Number(currentStep)) : setCurrentStep(0);
    propertyId ? setPropertyId(Number(propertyId)) : setPropertyId(0);
    respId ? setRespId(Number(respId)) : setRespId(0);
    managementId ? setManagementId(Number(managementId)) : setManagementId(0);

    if (token && user) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    setLoading(true);
    try {
      const response = await api.post('/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      if (user.user_type !== 'ADMIN' && user.user_type !== 'SUPERADMIN') {
        toast.error('Este usuario não tem permissão para acessar este site!');
        return;
      }

      localStorage.setItem('@GetapAdm:token', token);
      localStorage.setItem('@GetapAdm:user', JSON.stringify(user));

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      setData({ token, user });
      setLoading(false);
    } catch (e) {
      toast.error('E-mail/senha não conferem!');
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@GetapAdm:token');
    localStorage.removeItem('@GetapAdm:user');

    setData({} as AuthState);
  }, []);

  const setStep = useCallback((value: number) => {
    setCurrentStep(value);
    localStorage.setItem('@GetapAdm:step', `${value}`);
  }, []);

  const setResp = useCallback((value: number) => {
    setRespId(value);
    localStorage.setItem('@GetapAdm:resp', `${value}`);
  }, []);
  const setProperty = useCallback((value: number) => {
    setPropertyId(value);
    localStorage.setItem('@GetapAdm:property', `${value}`);
  }, []);
  const setManagement = useCallback((value: number) => {
    setManagementId(value);
    localStorage.setItem('@GetapAdm:management', `${value}`);
  }, []);

  useEffect(() => {
    const subscribe = api.registerInterceptTokenManager(signOut);

    return () => {
      subscribe();
    };
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        loading,
        currentStep,
        setStep,
        respId,
        setResp,
        propertyId,
        setProperty,
        managementId,
        setManagement,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
