import React from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';

interface DataManejo {
  data: ISubscription;
}

export const InfoManejoImplantacaoCultura: React.FC<DataManejo> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Onde realizou tratamento de sementes:{' '}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.accomplished}</C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>Inoculates: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.inoculate}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Bioestimulantes: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.biostimulant}</C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>Quantidade de defensivos: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.defensive_quantity}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Defensivo 1: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.def_1}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Defensivo 2: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.def_2}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Defensivo 3: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.def_3}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Defensivo 4: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.def_4}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
      <C.BoxLabelTitleSection>Maquinário</C.BoxLabelTitleSection>
      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fabricante da semeadora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.manufacturer_seeder?.name}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Modelo e marca da semeadora: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.model_seeder}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Ano da semeadora: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.year_seeder}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fabricante do pulverizador: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.manufacturer_sprayer?.name}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Modelo e marca do pulverizador: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.model_sprayer}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Ano do pulverizador: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.seed_treatment?.year_sprayer}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fabricante da colhedora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.manufacturer_harverster?.name}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Modelo e marca da colhedora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.model_harverster}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Ano da colhedora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.seed_treatment?.year_harverster}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col>
    </C.ContainerDadosPropriedade>
  );
};
