import { ISubscription } from '../../@types/types';
import * as C from './styles';

interface IData {
  data: ISubscription;
}

export const PagamentoInscricao = ({ data }: IData) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Forma de pagamento: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.coupom_id
              ? `Cupom - ${data?.coupom?.code}`
              : data?.payment_id}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
