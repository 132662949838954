import React, { useMemo } from 'react';
import * as C from './styles';
import { format } from 'date-fns';
import { ISubscription } from '../../@types/types';

type DataProp = {
  data: ISubscription;
};

export const Agendamento: React.FC<DataProp> = ({ data }) => {
  const previsaoCol = useMemo(() => {
    return data.appointment !== null
      ? format(new Date(data.appointment.harvest_forecast), 'dd/MM/yyyy')
      : '-';
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabel>
        <C.BoxLabelTitle>Previsão de colheita (dia/mês/ano) </C.BoxLabelTitle>
        <C.BoxLabelValue>{previsaoCol}</C.BoxLabelValue>
      </C.BoxLabel>
      <h4>Contato de quem acompanhará a auditoria:</h4>
      <C.BoxLabel>
        <C.BoxLabelTitle>Nome:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data.appointment?.companion_name}</C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone/WhatsApp [opção 01]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.appointment?.companion_phone1}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone/WhatsApp [opção 02]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.appointment?.companion_phone2}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col>
    </C.ContainerDadosPropriedade>
  );
};
