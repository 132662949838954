import { ISubscription } from '../../@types/types';
import * as C from './styles';

interface IData {
  data: ISubscription;
}

export const Regulamento = ({ data }: IData) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Aceite dos termos: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.accept_terms ? 'Sim' : 'Não'}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
