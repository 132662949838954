import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import 'devextreme/dist/css/dx.light.css';

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from 'devextreme-react/html-editor';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { NativeEventInfo } from 'devextreme/events';
import dxHtmlEditor from 'devextreme/ui/html_editor';
import { ValueChangedInfo } from 'devextreme/ui/editor/editor';
import api from '../../services/api';
import Load from '../../components/Load';

export const Regulamento = React.memo(() => {
  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
  ];
  const headerValues = [false, 1, 2, 3, 4, 5];

  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState('');
  const editorRef = useRef(null);

  async function loadData() {
    setLoading(true);
    api
      .get('/system/term')
      .then((res) => {
        if (res.data === undefined) {
          setTerms('');
          return;
        }

        setTerms(res.data.description);
        return;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    loadData();
  }, []);

  const handleValueChange = useCallback(
    (e: NativeEventInfo<dxHtmlEditor, Event> & ValueChangedInfo) => {
      editorRef.current = e.value; // Atualiza a referência
    },
    []
  );

  const createTerms = useCallback(async () => {
    if (editorRef.current === '') {
      return toast.error('Atenção! preencha todos os campos.');
    }

    api
      .post('/admin/term', {
        description: editorRef.current,
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success('Termo de uso cadastrado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, []);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Regulamento' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <h2>Cadastrar novo regulamento</h2>
            {loading && <Load />}

            {!loading && (
              <HtmlEditor
                height='600px'
                onValueChanged={handleValueChange}
                defaultValue={terms}
              >
                <MediaResizing enabled={true} />
                <ImageUpload fileUploadMode='base64' />
                <Toolbar multiline={true}>
                  <Item name='undo' />
                  <Item name='redo' />
                  <Item name='separator' />
                  <Item name='size' acceptedValues={sizeValues} />
                  <Item name='font' acceptedValues={fontValues} />
                  <Item name='separator' />
                  <Item name='bold' />
                  <Item name='italic' />
                  <Item name='strike' />
                  <Item name='underline' />
                  <Item name='separator' />
                  <Item name='alignLeft' />
                  <Item name='alignCenter' />
                  <Item name='alignRight' />
                  <Item name='alignJustify' />
                  <Item name='separator' />
                  <Item name='orderedList' />
                  <Item name='bulletList' />
                  <Item name='separator' />
                  <Item name='header' acceptedValues={headerValues} />
                  <Item name='separator' />
                  <Item name='color' />
                  <Item name='background' />
                  <Item name='separator' />
                  <Item name='link' />
                  <Item name='image' />
                  <Item name='separator' />
                  <Item name='clear' />
                  <Item name='codeBlock' />
                  <Item name='blockquote' />
                  <Item name='separator' />
                  <Item name='insertTable' />
                  <Item name='deleteTable' />
                  <Item name='insertRowAbove' />
                  <Item name='insertRowBelow' />
                  <Item name='deleteRow' />
                  <Item name='insertColumnLeft' />
                  <Item name='insertColumnRight' />
                  <Item name='deleteColumn' />
                </Toolbar>
              </HtmlEditor>
            )}
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button title='SALVAR' color='#27AE60' onClick={createTerms} />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
});
