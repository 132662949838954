import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { BiPlus } from 'react-icons/bi';
import { DataGrid, SelectBox, TextBox } from 'devextreme-react';
import { UF, producer_company_type } from '../../data/data';
import { Column, Button, SearchPanel } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TProducerCompany } from '../../types/PropertyTypes';
import api, { cepApi } from '../../services/api';
import { toast } from 'react-toastify';
import Autocomplete from 'devextreme-react/autocomplete';
import municipiosData from '../../data/municipios.json';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { removeSpacesAndSpecialChars } from '../../utils/StringUtils';
import Load from '../../components/Load';

export const ProdutoresEmpresas = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [allProducers, setAllProducers] = useState<TProducerCompany[]>([]);

  const [idRow, setIdRow] = useState();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const [address, setAddress] = useState('');
  const [numAddress, setNumAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [complement, setComplement] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [name_resp, setNameResp] = useState('');
  const [phone_resp, setPhoneResp] = useState('');
  const [type, setType] = useState('');
  const [load, setLoad] = useState(false);

  const togglePopup = () => setPopupVisibility(!isPopupVisible);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const togglePopupEdit = () => setPopupEditVisibility(!isPopupEditVisible);
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  const updateDatasource = (e: string) => {
    // municipiosData.filter(i => i["UF"] === e);
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  const listProdutoresEmpresas = useCallback(() => {
    setLoad(true);
    api
      .get('/admin/producer?page=1&pagesize=5000')
      .then((res) => {
        console.log(res.data);
        setAllProducers(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoad(false);
      });
  }, []);

  const validateFields = () => {
    // let validateCompanyFields = false;
    // if (type === 'Empresa') {
    //     validateCompanyFields = nome_resp === '' || phone_resp === '';
    // }
    let validateCompanyFields =
      type === 'Empresa' ? name_resp === '' || phone_resp === '' : true;
    return (
      !(
        name === '' ||
        email === '' ||
        phone === '' ||
        cpf_cnpj === '' ||
        address === '' ||
        city === '' ||
        uf === '' ||
        type === ''
      ) || !validateCompanyFields
    );
  };

  // const handleValueChangeCidade = (e: any) => {
  //     const item = municipiosPopup.find(h => h.MUN.toLowerCase().startsWith(e.value.toLowerCase()));
  //     console.log(`item = ${item}`);
  //     // const item = hibridosData.find(i => i.Cultivar === e.value);
  //     setCity(e.value);
  //     if (!item) {
  //         toast.warning(`Por favor informe um valor válido para a cidade.`);
  //     }
  // };

  const handleOnFocusOutCidade = (e: any) => {
    if (city === undefined || city === '') {
      return;
    }
    const item = municipiosPopup.find((i) => i.MUN === city);
    if (!item) {
      setCity(''); // Limpa o campo ou mantenha o último valor válido
      toast.warning(`Por favor informe um valor válido para a cidade.`);
    }
  };

  const createProducerCompany = () => {
    if (!validateFields()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    const pure = removeSpacesAndSpecialChars(cpf_cnpj!);

    if (type === 'Produtor') {
      if (!cpf.isValid(pure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      if (!cnpj.isValid(pure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    api
      .post('/admin/producer', {
        name,
        email,
        phone,
        document: pure,
        zip_code,
        address,
        number: numAddress,
        complement,
        district,
        city,
        uf,
        name_resp: type === 'Empresa' ? name_resp : '',
        phone_resp: type === 'Empresa' ? phone_resp : '',
        is_enterprise: type === 'Empresa',
      })
      .then((res) => {
        if (res.status === 201) {
          setPopupVisibility(false);
          toast.success('Registro criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        listProdutoresEmpresas();
      });
  };

  const removerRegistro = () => {
    api
      .delete(`/adm/producer-company/${idRow}`)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        listProdutoresEmpresas();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error('Error ao remover o registro.');
      })
      .finally(() => {
        setModalDelete(false);
      });
  };

  const alteraRegistro = () => {
    if (!validateFields()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    const pure = removeSpacesAndSpecialChars(cpf_cnpj!);
    console.log(`pure = ${pure}`);
    if (type === 'Produtor') {
      if (!cpf.isValid(pure || '')) {
        return toast.error('Preencha um CPF válido!');
      }
    } else {
      if (!cnpj.isValid(pure || '')) {
        return toast.error('Preencha um CNPJ válido!');
      }
    }

    api
      .put(`/admin/producer/${idRow}`, {
        name,
        email,
        phone,
        document: pure,
        zip_code,
        address,
        number: numAddress,
        complement,
        district,
        city,
        uf,
        name_resp: type === 'Empresa' ? name_resp : '',
        phone_resp: type === 'Empresa' ? phone_resp : '',
        is_enterprise: type === 'Empresa',
      })
      .then((res) => {
        if (res.status === 200) {
          setPopupVisibility(false);
          toast.success('Registro alterado com sucesso!');
          // clearProducerCompanyData();
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        listProdutoresEmpresas();
        setPopupEditVisibility(false);
      });
  };

  // const getIdToDelete = (e: any) => {
  //     setIdRow(e.row.key);
  //     setModalDelete(true)
  // }

  const getProducerCompanyData = (e: any) => {
    console.log(e.row.data);
    setIdRow(e.row.key);
    setName(e.row.data.name);
    setEmail(e.row.data.email);
    setPhone(e.row.data.phone);
    setCpfCnpj(e.row.data.document);
    setAddress(e.row.data.address);
    setCity(e.row.data.city);
    setUf(e.row.data.uf);
    setNameResp(e.row.data.name_resp);
    setPhoneResp(e.row.data.phone_resp);
    setType(e.row.data.is_enterprise ? 'Empresa' : 'Produtor');
    setZipCode(e.row.data.zip_code);
    setNumAddress(e.row.data.number);
    setDistrict(e.row.data.district);
    setComplement(e.row.data.complement);

    setPopupEditVisibility(true);
  };

  useEffect(() => {
    listProdutoresEmpresas();
  }, [listProdutoresEmpresas]);

  useEffect(() => {
    if (zip_code) {
      const pure = removeSpacesAndSpecialChars(zip_code);
      if (pure.length === 8) {
        setLoad(true);
        cepApi
          .get(`/${pure}`)
          .then((res) => {
            console.log(res.data);
            setAddress(res.data.street);
            setDistrict(res.data.neighborhood);
            setCity(res.data.city);
            setUf(res.data.state);
          })
          .catch((error) => {
            toast.error('Error ao buscar o cep.');
          })
          .finally(() => {
            setLoad(false);
          });
      }
    }
  }, [zip_code]);

  return (
    <C.Container>
      {load && <Load />}
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Produtores / Empresas' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.BoxRegulamento>
              <C.BoxButtonSubmit>
                <button onClick={togglePopup}>
                  <BiPlus size={16} />
                  Cadastrar
                </button>
              </C.BoxButtonSubmit>
              <br />
              <br />
              <DataGrid
                id='cupom'
                dataSource={allProducers}
                keyExpr='id'
                showBorders={false}
                showColumnLines={false}
                columnResizingMode='nextColumn'
                allowColumnResizing={true}
                rowAlternationEnabled={true}
              >
                <SearchPanel visible={true} />
                <Column caption='Nome' dataField='name' />
                <Column caption='Email' dataField='email' />
                <Column caption='Phone' dataField='phone' />
                <Column
                  caption='Cpf/Cnpj'
                  dataField='document'
                  cellRender={(cell) =>
                    cell.value
                      ? cpf.format(cell.value)
                      : cnpj.format(cell.value)
                  }
                />
                {/* <Column caption="Endereço" dataField='address' /> */}
                <Column caption='Cidade' dataField='city' />
                <Column caption='UF' dataField='uf' />
                <Column
                  caption='Tipo'
                  dataField='is_enterprise'
                  cellRender={(cell) => (cell.value ? 'Empresa' : 'Produtor')}
                />
                {/* <Column caption="Nome Responsável" dataField='name_resp' /> */}
                {/* <Column caption="Phone Responsável" dataField='phone_resp' /> */}
                {/* <Column caption="Data de criação" dataField='created_at' /> */}
                {/* <Column caption="Data de edição" dataField='updated_at' /> */}
                <Column
                  caption='AÇÕES'
                  width={100}
                  alignment='center'
                  type='buttons'
                >
                  <Button
                    name='editar'
                    icon='edit'
                    onClick={(e: any) => getProducerCompanyData(e)}
                  />
                  {/* <Button name='excluir' icon='remove' onClick={(e: any) => getIdToDelete(e)} /> */}
                </Column>
              </DataGrid>
            </C.BoxRegulamento>
          </C.BoxFields>
        </C.ContentRightForm>

        <Popup
          title='Cadastro de produtores / empresas'
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopup}
          width={'95%'}
          height={'95vh'}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <SelectBox
                items={producer_company_type}
                value={type}
                onValueChange={(e) => {
                  setType(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label='Tipo (*)'
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                stylingMode='underlined'
                value={name}
                labelMode='static'
                label={`Nome ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Digite um nome...'
                width='100%'
                height={50}
                onValueChanged={(e) => {
                  setName(e.value);
                  console.log(`nome = ${e.value}`);
                }}
              />
              <TextBox
                stylingMode='underlined'
                value={email}
                labelMode='static'
                label={`Email ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Email'
                width='100%'
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={phone}
                labelMode='static'
                label={`Telefone ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um telefone...'
                width='100%'
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={cpf_cnpj}
                labelMode='static'
                label={`${
                  type === 'Empresa' ? 'CNPJ Empresa' : 'CPF Produtor'
                } (*)`}
                placeholder='Um CPF ou CNPJ...'
                width='100%'
                height={50}
                mask={
                  type === 'Empresa' ? '00.000.000/0000-00' : '000.000.000-00'
                }
                onValueChanged={(e) => setCpfCnpj(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                label='CEP'
                value={zip_code}
                labelMode='static'
                placeholder='00.000-000'
                width='100%'
                height={50}
                mask='00.000-000'
                onValueChanged={(e) => setZipCode(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={address}
                labelMode='static'
                label={`Endereço ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um endereço...'
                width='100%'
                height={50}
                onValueChanged={(e) => setAddress(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={numAddress}
                labelMode='static'
                label={`N° ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um número...'
                width='100%'
                height={50}
                onValueChanged={(e) => setNumAddress(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={district}
                labelMode='static'
                label={`Bairro ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um bairro...'
                width='100%'
                height={50}
                onValueChanged={(e) => setDistrict(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={complement}
                labelMode='static'
                label='Complemento'
                placeholder='Um complemento...'
                width='100%'
                height={50}
                onValueChanged={(e) => setComplement(e.value)}
              />
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <Autocomplete
                width='100%'
                height={50}
                dataSource={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                value={city}
                label={`Cidade ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                onFocusOut={handleOnFocusOutCidade}
                labelMode='static'
                stylingMode='underlined'
              />

              {type === 'Empresa' && (
                <TextBox
                  stylingMode='underlined'
                  // value={nome_resp}
                  labelMode='static'
                  label='Nome do Responsável Empresa(*)'
                  placeholder='Um nome de responsável...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setNameResp(e.value)}
                />
              )}
              {type === 'Empresa' && (
                <TextBox
                  stylingMode='underlined'
                  // value={phone_resp}
                  labelMode='static'
                  label='Telefone do Responsável Empresa(*)'
                  mask='(00) 00000-0000'
                  placeholder='Um telefone...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setPhoneResp(e.value)}
                />
              )}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createProducerCompany}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Remover Produtor / Empresa'
          visible={modalDelete}
          hideOnOutsideClick={true}
          onHiding={toggleDelete}
          width={400}
          height={200}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <p>Tem certdeza que deseja remover esse registro?</p>
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setModalDelete(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={removerRegistro}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Edição de produtores / empresas'
          visible={isPopupEditVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopupEdit}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <SelectBox
                items={producer_company_type}
                value={type}
                onValueChange={(e) => {
                  setType(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label='Tipo (*)'
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <TextBox
                stylingMode='underlined'
                value={name}
                labelMode='static'
                label={`Nome ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Digite um nome...'
                width='100%'
                height={50}
                onValueChanged={(e) => {
                  setName(e.value);
                }}
              />
              <TextBox
                stylingMode='underlined'
                value={email}
                labelMode='static'
                label={`Email ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Email'
                width='100%'
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={phone}
                labelMode='static'
                label={`Fone ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um telefone...'
                width='100%'
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={cpf_cnpj}
                labelMode='static'
                label={`${
                  type === 'Empresa' ? 'CNPJ Empresa' : 'CPF Produtor'
                } (*)`}
                placeholder='Um CPF ou CNPJ...'
                width='100%'
                height={50}
                mask={
                  type === 'Empresa' ? '00.000.000/0000-00' : '000.000.000-00'
                }
                onValueChanged={(e) => setCpfCnpj(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                label='CEP'
                value={zip_code}
                labelMode='static'
                placeholder='00.000-000'
                width='100%'
                height={50}
                mask='00.000-000'
                onValueChanged={(e) => setZipCode(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={address}
                labelMode='static'
                label={`Endereço ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um endereço...'
                width='100%'
                height={50}
                onValueChanged={(e) => setAddress(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={numAddress}
                labelMode='static'
                label={`N° ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um número...'
                width='100%'
                height={50}
                onValueChanged={(e) => setNumAddress(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={district}
                labelMode='static'
                label={`Bairro ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                placeholder='Um bairro...'
                width='100%'
                height={50}
                onValueChanged={(e) => setDistrict(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={complement}
                labelMode='static'
                label='Complemento'
                placeholder='Um complemento...'
                width='100%'
                height={50}
                onValueChanged={(e) => setComplement(e.value)}
              />
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <Autocomplete
                width='100%'
                height={50}
                dataSource={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                value={city}
                label={`Cidade ${
                  type === 'Empresa' ? 'da empresa' : 'do produtor'
                } (*)`}
                onFocusOut={handleOnFocusOutCidade}
                labelMode='static'
                stylingMode='underlined'
              />

              {type === 'Empresa' && (
                <TextBox
                  stylingMode='underlined'
                  value={name_resp}
                  labelMode='static'
                  label='Nome do Responsável Empresa(*)'
                  placeholder='Um nome de responsável...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setNameResp(e.value)}
                />
              )}
              {type === 'Empresa' && (
                <TextBox
                  stylingMode='underlined'
                  value={phone_resp}
                  labelMode='static'
                  label='Telefone do Responsável Empresa(*)'
                  mask='(00) 00000-0000'
                  placeholder='Um telefone...'
                  width='100%'
                  height={50}
                  onValueChanged={(e) => setPhoneResp(e.value)}
                />
              )}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setPopupEditVisibility(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={alteraRegistro}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>
      </C.ContentRight>
    </C.Container>
  );
};
