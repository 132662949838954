import React, { useMemo } from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';

interface DataManejo {
  data: ISubscription;
}

export const InfoManejoProtecaoCultivos: React.FC<DataManejo> = ({ data }) => {
  const mInfos = useMemo(() => {
    const LAGARTA =
      data.insecticide_caterpillars !== undefined &&
      data.insecticide_caterpillars > 0
        ? data.protections?.filter(
            (i) => i.protection_type === 'INSETICIDA_LAGARTA'
          )
        : [];
    const CIGARRA =
      data.insecticide_cicadas !== undefined && data.insecticide_cicadas > 0
        ? data.protections?.filter(
            (i) => i.protection_type === 'INSETICIDA_CIGARRA'
          )
        : [];
    const FUNGICIDA =
      data.fungicide !== undefined && data.fungicide > 0
        ? data.protections?.filter((i) => i.protection_type === 'FUNGICIDA')
        : [];
    const NEMATICIDA =
      data.biodefencive !== undefined && data.biodefencive > 0
        ? data.protections?.filter((i) => i.protection_type === 'BIODEFENCIVO')
        : [];
    const HERBICIDA =
      data.herbicide !== undefined && data.herbicide > 0
        ? data.protections?.filter((i) => i.protection_type === 'HERBICIDA')
        : [];

    return {
      LAGARTA,
      CIGARRA,
      FUNGICIDA,
      NEMATICIDA,
      HERBICIDA,
    };
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <>
        <C.BoxLabelTitleSection>Tipo de aplicação</C.BoxLabelTitleSection>
        <br />
        <h4>Herbicida ({data.herbicide} aplicações)</h4>
        {mInfos.HERBICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.HERBICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.name}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.active_ingredient}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.dose}
                    {pre?.unit_measurement}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.manufacturer}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Fenológico</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.vegetative_stage}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.vegetative_stage === 'Outros' && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>
          Inseticida (largatas) ({data.insecticide_caterpillars} aplicações)
        </h4>
        {mInfos.LAGARTA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.LAGARTA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.name}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.active_ingredient}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.dose}
                    {pre?.unit_measurement}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.manufacturer}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Fenológico</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.vegetative_stage}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.vegetative_stage === 'Outros' && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>
          Inseticida (cigarrinhas) ({data.insecticide_cicadas} aplicações)
        </h4>
        {mInfos.CIGARRA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.CIGARRA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.name}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.active_ingredient}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.dose}
                    {pre?.unit_measurement}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.manufacturer}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Fenológico</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.vegetative_stage}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.vegetative_stage === 'Outros' && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>Fungicida ({data.fungicide} aplicações)</h4>
        {mInfos.FUNGICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.FUNGICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.name}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.active_ingredient}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.dose}
                    {pre?.unit_measurement}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.manufacturer}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Fenológico</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.vegetative_stage}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.vegetative_stage === 'Outros' && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>Biodefensivos ({data.biodefencive} aplicações)</h4>
        {mInfos.NEMATICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.NEMATICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.name}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.active_ingredient}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.dose}
                    {pre?.unit_measurement}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>
                    {pre?.product_protection.manufacturer}
                  </C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Fenológico</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.vegetative_stage}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.vegetative_stage === 'Outros' && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}
      </>
    </C.ContainerDadosPropriedade>
  );
};
