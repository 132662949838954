import React, { useMemo } from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';

interface DataManejo {
  data: ISubscription;
}

export const InfoManejoNutricaoPlantas: React.FC<DataManejo> = ({ data }) => {
  const mInfos = useMemo(() => {
    const PRESEMEADURA =
      data?.pre_sowing !== undefined && data?.pre_sowing > 0
        ? data?.nutritions?.filter((i) => i.nutrition_type === 'PRESEMEADURA')
        : [];
    const SEMEADURA =
      data?.sowing !== undefined && data?.sowing > 0
        ? data?.nutritions?.filter((i) => i.nutrition_type === 'SEMEADURA')
        : [];
    const COBERTURA =
      data?.coverage !== undefined && data?.coverage > 0
        ? data?.nutritions?.filter((i) => i.nutrition_type === 'COBERTURA')
        : [];
    const FOLIAR =
      data?.leaf !== undefined && data?.leaf > 0
        ? data?.nutritions?.filter((i) => i.nutrition_type === 'FOLIAR')
        : [];
    const VEGETAL =
      data?.specialties !== undefined && data?.specialties > 0
        ? data?.nutritions?.filter((i) => i.nutrition_type === 'ESPECIALIDADES')
        : [];

    return {
      PRESEMEADURA,
      SEMEADURA,
      COBERTURA,
      FOLIAR,
      VEGETAL,
    };
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>Tipo de adubação</C.BoxLabelTitleSection>
      <br />
      <h4>Adubação pré-semeadura ({data.pre_sowing} aplicações):</h4>
      {mInfos.PRESEMEADURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.PRESEMEADURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nitrogênio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nitrogen}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fósforo</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phosphorus}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Potássio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.potassium}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Outros nutrientes</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.other_nutrients}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                <C.BoxLabelValue>
                  {pre?.dose}
                  {pre?.unit_measurement}
                </C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.comercial_name}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.manufacturer}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phenological_stage}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.phenological_stage === 'Outros' && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>Adubação de semeadura ({data.sowing} aplicações):</h4>
      {mInfos.SEMEADURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.SEMEADURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nitrogênio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nitrogen}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fósforo</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phosphorus}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Potássio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.potassium}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Outros nutrientes</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.other_nutrients}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                <C.BoxLabelValue>
                  {pre?.dose}
                  {pre?.unit_measurement}
                </C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.comercial_name}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.manufacturer}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phenological_stage}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.phenological_stage === 'Outros' && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>Adubação de cobertura ({data.coverage} aplicações):</h4>
      {mInfos.COBERTURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.COBERTURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nitrogênio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nitrogen}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fósforo</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phosphorus}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Potássio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.potassium}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Outros nutrientes</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.other_nutrients}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                <C.BoxLabelValue>
                  {pre?.dose}
                  {pre?.unit_measurement}
                </C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.comercial_name}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.manufacturer}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phenological_stage}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.phenological_stage === 'Outros' && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>Adubação foliar ({data.leaf} aplicações):</h4>
      {mInfos.FOLIAR.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.FOLIAR.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nitrogênio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nitrogen}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fósforo</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phosphorus}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Potássio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.potassium}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Outros nutrientes</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.other_nutrients}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                <C.BoxLabelValue>
                  {pre?.dose}
                  {pre?.unit_measurement}
                </C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.comercial_name}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.manufacturer}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phenological_stage}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.phenological_stage === 'Outros' && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>
        Especialidades/Fisiologia (condicionadores de solo,estimulantes, etc) (
        {data.specialties} aplicações):
      </h4>
      {mInfos.VEGETAL.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.VEGETAL.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nitrogênio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nitrogen}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fósforo</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phosphorus}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Potássio</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.potassium}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Outros nutrientes</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.other_nutrients}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose</C.BoxLabelTitle>
                <C.BoxLabelValue>
                  {pre?.dose}
                  {pre?.unit_measurement}
                </C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.comercial_name}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.manufacturer}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.phenological_stage}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.phenological_stage === 'Outros' && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.name_others}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}
    </C.ContainerDadosPropriedade>
  );
};
