import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { Painel } from '../pages/Painel/Painel';
import { Regulamento } from '../pages/Regulamento/Regulamento';
import { Propriedades } from '../pages/Propriedades/Propriedades';
import { Login } from '../pages/Login/Login';
import { NotFound } from '../pages/NotFound';
import { useAuth } from '../hooks/auth';
import { Theme } from '../components/Theme/Theme';
import { FormProvider } from '../contexts/FormContext';
import { Cupons } from '../pages/Cupons/Cupons';
import { PropriedadeView } from '../pages/PropriedadeView/PropriedadeView';
import { Usuarios } from '../pages/Usuarios/Usuarios';
import { Vistorias } from '../pages/Vistorias/Vistorias';
import { Settings } from '../pages/Settings';
import { ProdutoresEmpresas } from '../pages/ProdutoresEmpresas/ProdutoresEmpresas';
import { CadastroListas } from '../pages/CadastroListas/CadastroListas';
import { Hibridos } from '../pages/Hibridos';
import { Defensivos } from '../pages/Defensivos';
import { FabricanteMaquina } from '../pages/FabricanteMaquina';
import { FabricanteFertilizante } from '../pages/FabricanteFertilizante';
import { Rtvs } from '../pages/Rtvs/Rtvs';

export const RoutesApp: React.FC = () => {
  const { user } = useAuth();

  function Auth({ children }: { children: JSX.Element }) {
    let location = useLocation();

    if (!user) {
      return <Navigate to='/' state={{ from: location }} replace />;
    }
    return children;
  }

  return (
    <BrowserRouter>
      <FormProvider>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route
            path='/painel'
            element={
              <Auth>
                <Theme>
                  <Painel />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/regulamento'
            element={
              <Auth>
                <Theme>
                  <Regulamento />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/hibridos'
            element={
              <Auth>
                <Theme>
                  <Hibridos />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/defensivos'
            element={
              <Auth>
                <Theme>
                  <Defensivos />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/fabricantes'
            element={
              <Auth>
                <Theme>
                  <FabricanteMaquina />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/fabricantesFertilizantes'
            element={
              <Auth>
                <Theme>
                  <FabricanteFertilizante />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/cupons'
            element={
              <Auth>
                <Theme>
                  <Cupons />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/propriedades'
            element={
              <Auth>
                <Theme>
                  <Propriedades />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/usuarios'
            element={
              <Auth>
                <Theme>
                  <Usuarios />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/configuracoes'
            element={
              <Auth>
                <Theme>
                  <Settings />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/vistorias'
            element={
              <Auth>
                <Theme>
                  <Vistorias />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/propriedades/view/:id'
            element={
              <Auth>
                <Theme>
                  <PropriedadeView />
                </Theme>
              </Auth>
            }
          />
          <Route path='*' element={<NotFound />} />
          <Route
            path='/produtoresEmpresas'
            element={
              <Auth>
                <Theme>
                  <ProdutoresEmpresas />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/rtvs'
            element={
              <Auth>
                <Theme>
                  <Rtvs />
                </Theme>
              </Auth>
            }
          />
          <Route
            path='/cadastroListas'
            element={
              <Auth>
                <Theme>
                  <CadastroListas />
                </Theme>
              </Auth>
            }
          />
        </Routes>
      </FormProvider>
    </BrowserRouter>
  );
};
