import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { BiPlus } from 'react-icons/bi';
import { DataGrid, SelectBox, TextBox } from 'devextreme-react';
import { UF } from '../../data/data';
import { Column, Button, SearchPanel } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TProducerCompany } from '../../types/PropertyTypes';
import api from '../../services/api';
import { toast } from 'react-toastify';
import Autocomplete from 'devextreme-react/autocomplete';
import municipiosData from '../../data/municipios.json';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { removeSpacesAndSpecialChars } from '../../utils/StringUtils';
import Load from '../../components/Load';

export const Rtvs = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [allProducers, setAllProducers] = useState<TProducerCompany[]>([]);

  const [idRow, setIdRow] = useState();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [load, setLoad] = useState(false);

  const togglePopup = () => setPopupVisibility(!isPopupVisible);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const togglePopupEdit = () => setPopupEditVisibility(!isPopupEditVisible);
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData);

  const updateDatasource = (e: string) => {
    // municipiosData.filter(i => i["UF"] === e);
    setMunicipiosPopup(municipiosData.filter((i) => i['UF'] === e));
  };

  const listProdutoresEmpresas = useCallback(() => {
    setLoad(true);
    api
      .get('/admin/rtvs?page=1&pagesize=5000')
      .then((res) => {
        console.log(res.data);
        setAllProducers(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoad(false);
      });
  }, []);

  const validateFields = () => {
    // let validateCompanyFields = false;
    // if (type === 'Empresa') {
    //     validateCompanyFields = nome_resp === '' || phone_resp === '';
    // }

    return !(
      name === '' ||
      email === '' ||
      phone === '' ||
      cpf_cnpj === '' ||
      city === '' ||
      uf === ''
    );
  };

  const handleOnFocusOutCidade = (e: any) => {
    if (city === undefined || city === '') {
      return;
    }
    const item = municipiosPopup.find((i) => i.MUN === city);
    if (!item) {
      setCity(''); // Limpa o campo ou mantenha o último valor válido
      toast.warning(`Por favor informe um valor válido para a cidade.`);
    }
  };

  const createProducerCompany = () => {
    if (!validateFields()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    const pure = removeSpacesAndSpecialChars(cpf_cnpj!);

    if (!cpf.isValid(pure || '')) {
      return toast.error('Preencha um CPF válido!');
    }

    api
      .post('/admin/rtvs', {
        name,
        email,
        phone,
        document: pure,
        city,
        uf,
      })
      .then((res) => {
        if (res.status === 201) {
          setPopupVisibility(false);
          toast.success('Registro criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        listProdutoresEmpresas();
      });
  };

  const removerRegistro = () => {
    api
      .delete(`/adm/producer-company/${idRow}`)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        listProdutoresEmpresas();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error('Error ao remover o registro.');
      })
      .finally(() => {
        setModalDelete(false);
      });
  };

  const alteraRegistro = () => {
    if (!validateFields()) {
      return toast.error('Atenção! preencha todos os campos.');
    }

    const pure = removeSpacesAndSpecialChars(cpf_cnpj!);

    if (!cpf.isValid(pure || '')) {
      return toast.error('Preencha um CPF válido!');
    }

    api
      .put(`/admin/rtvs/${idRow}`, {
        name,
        email,
        phone,
        document: pure,
        city,
        uf,
      })
      .then((res) => {
        if (res.status === 200) {
          setPopupVisibility(false);
          toast.success('Registro alterado com sucesso!');
          // clearProducerCompanyData();
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      })
      .finally(() => {
        listProdutoresEmpresas();
        setPopupEditVisibility(false);
      });
  };

  const getProducerCompanyData = (e: any) => {
    setIdRow(e.row.key);
    setName(e.row.data.name);
    setEmail(e.row.data.email);
    setPhone(e.row.data.phone);
    setCpfCnpj(e.row.data.document);
    setCity(e.row.data.city);
    setUf(e.row.data.uf);

    setPopupEditVisibility(true);
  };

  useEffect(() => {
    listProdutoresEmpresas();
  }, [listProdutoresEmpresas]);

  return (
    <C.Container>
      {load && <Load />}
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='RTVs' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.BoxRegulamento>
              <C.BoxButtonSubmit>
                <button onClick={togglePopup}>
                  <BiPlus size={16} />
                  Cadastrar
                </button>
              </C.BoxButtonSubmit>
              <br />
              <br />
              <DataGrid
                id='cupom'
                dataSource={allProducers}
                keyExpr='id'
                showBorders={false}
                showColumnLines={false}
                columnResizingMode='nextColumn'
                allowColumnResizing={true}
                rowAlternationEnabled={true}
              >
                <SearchPanel visible={true} />
                <Column caption='Nome' dataField='name' />
                <Column caption='Email' dataField='email' />
                <Column caption='Phone' dataField='phone' />
                <Column
                  caption='Cpf/Cnpj'
                  dataField='document'
                  cellRender={(cell) =>
                    cell.value
                      ? cpf.format(cell.value)
                      : cnpj.format(cell.value)
                  }
                />
                {/* <Column caption="Endereço" dataField='address' /> */}
                <Column caption='Cidade' dataField='city' />
                <Column caption='UF' dataField='uf' />
                <Column
                  caption='Tipo'
                  dataField='is_enterprise'
                  cellRender={(cell) => (cell.value ? 'Empresa' : 'Produtor')}
                />
                {/* <Column caption="Nome Responsável" dataField='name_resp' /> */}
                {/* <Column caption="Phone Responsável" dataField='phone_resp' /> */}
                {/* <Column caption="Data de criação" dataField='created_at' /> */}
                {/* <Column caption="Data de edição" dataField='updated_at' /> */}
                <Column
                  caption='AÇÕES'
                  width={100}
                  alignment='center'
                  type='buttons'
                >
                  <Button
                    name='editar'
                    icon='edit'
                    onClick={(e: any) => getProducerCompanyData(e)}
                  />
                  {/* <Button name='excluir' icon='remove' onClick={(e: any) => getIdToDelete(e)} /> */}
                </Column>
              </DataGrid>
            </C.BoxRegulamento>
          </C.BoxFields>
        </C.ContentRightForm>

        <Popup
          title='Cadastro de RTV'
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopup}
          width={'80%'}
          height={'60vh'}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                value={name}
                labelMode='static'
                label={`Nome (*)`}
                placeholder='Digite um nome...'
                width='100%'
                height={50}
                onValueChanged={(e) => {
                  setName(e.value);
                  console.log(`nome = ${e.value}`);
                }}
              />
              <TextBox
                stylingMode='underlined'
                value={email}
                labelMode='static'
                label={`Email (*)`}
                placeholder='Email'
                width='100%'
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={phone}
                labelMode='static'
                label={`Telefone (*)`}
                placeholder='Um telefone...'
                width='100%'
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={cpf_cnpj}
                labelMode='static'
                label='CPF'
                placeholder='Um CPF ou CNPJ...'
                width='100%'
                height={50}
                mask={'000.000.000-00'}
                onValueChanged={(e) => setCpfCnpj(e.value)}
              />
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF (*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <Autocomplete
                width='100%'
                height={50}
                dataSource={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                value={city}
                label={`Cidade (*)`}
                onFocusOut={handleOnFocusOutCidade}
                labelMode='static'
                stylingMode='underlined'
              />
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createProducerCompany}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Remover Produtor / Empresa'
          visible={modalDelete}
          hideOnOutsideClick={true}
          onHiding={toggleDelete}
          width={400}
          height={200}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <p>Tem certdeza que deseja remover esse registro?</p>
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setModalDelete(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={removerRegistro}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Edição de RTV'
          visible={isPopupEditVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopupEdit}
          width={'80%'}
          height={'60vh'}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                value={name}
                labelMode='static'
                label={`Nome (*)`}
                placeholder='Digite um nome...'
                width='100%'
                height={50}
                onValueChanged={(e) => {
                  setName(e.value);
                }}
              />
              <TextBox
                stylingMode='underlined'
                value={email}
                labelMode='static'
                label={`Email (*)`}
                placeholder='Email'
                width='100%'
                height={50}
                onValueChanged={(e) => setEmail(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={phone}
                labelMode='static'
                label={`Fone (*)`}
                placeholder='Um telefone...'
                width='100%'
                height={50}
                mask='(00) 0 0000-0000'
                onValueChanged={(e) => setPhone(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                value={cpf_cnpj}
                labelMode='static'
                label='CPF'
                placeholder='Um CPF ou CNPJ...'
                width='100%'
                height={50}
                mask={'000.000.000-00'}
                onValueChanged={(e) => setCpfCnpj(e.value)}
              />
              <SelectBox
                items={UF}
                value={uf}
                onValueChange={(e) => {
                  setUf(e);
                  updateDatasource(e);
                }}
                stylingMode='underlined'
                labelMode='static'
                label={`UF (*)`}
                width='100%'
                height={50}
                style={{ marginRight: '20px', color: '#444' }}
              />
              <Autocomplete
                width='100%'
                height={50}
                dataSource={municipiosPopup}
                valueExpr='MUN'
                searchExpr='MUN'
                value={city}
                label={`Cidade (*)`}
                onFocusOut={handleOnFocusOutCidade}
                labelMode='static'
                stylingMode='underlined'
              />
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setPopupEditVisibility(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={alteraRegistro}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>
      </C.ContentRight>
    </C.Container>
  );
};
