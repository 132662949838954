import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { BiPlus } from 'react-icons/bi';
import { DataGrid, TextBox } from 'devextreme-react';
import { Column, Button, SearchPanel } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TCupons } from '../../types/PropertyTypes';
import api from '../../services/api';
import { toast } from 'react-toastify';

export const FabricanteMaquina = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [data, setData] = useState<TCupons[]>([]);

  const [name, setName] = useState('');
  const [idRow, setIdRow] = useState();

  const togglePopup = () => setPopupVisibility(!isPopupVisible);
  const togglePopupEdit = () => setPopupEditVisibility(!isPopupEditVisible);

  const listData = useCallback(() => {
    api.get('/admin/manufacture').then((res) => {
      setData(res.data);
    });
  }, []);

  const createCupon = useCallback(async () => {
    if (name === '') {
      return toast.error('Atenção! preencha todos os campos.');
    }

    api
      .post('/admin/manufacture', {
        name,
      })
      .then((res) => {
        if (res.status === 201) {
          setPopupVisibility(false);
          toast.success('Cupom criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.message}`);
      })
      .finally(() => listData());
  }, [name, listData]);

  const updateData = () => {
    api
      .put(`/admin/manufacture/${idRow}`, {
        name,
      })
      .then((res) => {
        toast.success('Cupom alterado com sucesso!');
        listData();
      })
      .catch((error) => {
        toast.error('Error ao alterar o cupom.');
      })
      .finally(() => {
        setPopupEditVisibility(false);
      });
  };

  const getData = (e: any) => {
    setIdRow(e.row.key);
    setName(e.row.data.name);
    setPopupEditVisibility(true);
  };

  useEffect(() => {
    listData();
  }, [listData]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Fabricantes de Máquina' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.BoxRegulamento>
              <C.BoxButtonSubmit>
                <button onClick={togglePopup}>
                  <BiPlus size={16} />
                  Cadastrar
                </button>
              </C.BoxButtonSubmit>
              <br />
              <br />
              <DataGrid
                id='manufacture'
                dataSource={data}
                keyExpr='id'
                showBorders={false}
                showColumnLines={false}
                columnResizingMode='nextColumn'
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                style={{ width: 'auto' }}
              >
                <SearchPanel visible={true} />
                <Column caption='Nome' dataField='name' n-width='500' />

                <Column
                  caption='AÇÕES'
                  width={100}
                  alignment='center'
                  type='buttons'
                >
                  {/* <Button
                    name='desativar'
                    icon='preferences'
                    onClick={(e: any) => getIdCupom(e)}
                  /> */}
                  <Button
                    name='editar'
                    icon='edit'
                    onClick={(e: any) => getData(e)}
                  />
                  {/* <Button name='excluir' icon='remove' onClick={(e: any) => getIdCupomToDelete(e)} /> */}
                </Column>
              </DataGrid>
            </C.BoxRegulamento>
          </C.BoxFields>
        </C.ContentRightForm>

        <Popup
          title='Cadastro de fabricantes de máquinas'
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopup}
          width={400}
          height={500}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Nome'
                placeholder='Digite no nome...'
                width={320}
                height={50}
                onValueChanged={(e) => setName(e.value)}
              />
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createCupon}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Edição de cupom'
          visible={isPopupEditVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopupEdit}
          width={400}
          height={500}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Nome'
                placeholder='Digite no nome...'
                width={320}
                height={50}
                value={name}
                onValueChanged={(e) => setName(e.value)}
              />
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setPopupEditVisibility(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={updateData}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>
      </C.ContentRight>
    </C.Container>
  );
};
