import * as C from './styles';
import 'devextreme/dist/css/dx.light.css';
import { Title } from '../../components/Title/Title';
import { Switch } from 'devextreme-react/switch';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';

import { toast } from 'react-toastify';

import { Button } from '../../components/Button/Button';
import { TextBox } from 'devextreme-react';

export const Settings = () => {
  const [insc, setInsc] = useState(false);
  const [pix, setPix] = useState(false);
  const [card, setCard] = useState(false);
  const [cupom, setCupom] = useState(false);
  const [safra, setSafra] = useState('');

  async function loadData() {
    api.get('/system').then((res) => {
      if (res.data === undefined) {
        setInsc(true);
        setPix(true);
        setCard(true);
        setCupom(true);
        setSafra('');
        return;
      }

      setInsc(res.data.is_subscription);
      setPix(res.data.pix_payment);
      setCard(res.data.card_payment);
      setCupom(res.data.cupom);
      setSafra(res.data.harvest);
      return;
    });
  }

  const handleSave = useCallback(() => {
    console.log(insc, pix, card, cupom);
    api
      .post('/admin/config', {
        is_subscription: insc,
        pix_payment: pix,
        card_payment: card,
        cupom: cupom,
        harvest: safra,
      })
      .then((res) => {
        toast.success('Configuração salva com sucesso!');
      })
      .catch((e) => {
        toast.error(e);
      });
  }, [insc, pix, card, cupom, safra]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <C.Container>
      <C.HeaderContentRight>
        <Title title='Configurações' />
      </C.HeaderContentRight>
      <C.SectionTable>
        <div
          style={{
            width: '360px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <label>Incrições abertas</label>
          <Switch
            width={80}
            value={insc}
            onValueChange={() => setInsc(!insc)}
          />
        </div>
        <div
          style={{
            width: '360px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <label>Pagamentos com pix</label>
          <Switch width={80} value={pix} onValueChange={() => setPix(!pix)} />
        </div>
        <div
          style={{
            width: '360px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <label>Pagamentos com Cupom</label>
          <Switch
            width={80}
            value={cupom}
            onValueChange={() => setCupom(!cupom)}
          />
        </div>
        <div
          style={{
            width: '360px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <label>Pagamentos com cartão</label>
          <Switch
            width={80}
            value={card}
            onValueChange={() => setCard(!card)}
          />
        </div>
        <TextBox
          value={safra}
          onValueChange={setSafra}
          stylingMode='underlined'
          labelMode='static'
          label='Safra atual'
          width='360px'
          height={50}
          style={{ marginRight: '20px' }}
        />
        <div
          style={{
            width: '360px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <Button
            onClick={handleSave}
            title='Salvar alterações'
            color='#27AE60'
          />
        </div>
      </C.SectionTable>
    </C.Container>
  );
};
