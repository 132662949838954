import { ReactNode } from 'react';
import * as C from './styles';

type PropsCard = {
  title: string;
  value: number;
  icon?: ReactNode;
  left_desc: string;
  right_desc: string;
  left_val: number;
  right_val: number;
};

export const CardInfoDetails = ({
  title,
  value,
  icon,
  left_desc,
  left_val,
  right_desc,
  right_val,
}: PropsCard) => {
  return (
    <C.CardsMetrica>
      <h3>{title}</h3>
      <C.CardMetricaValue>
        <C.CardMetricaIcon>{icon}</C.CardMetricaIcon>
        <span>{value}</span>
      </C.CardMetricaValue>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <p style={{ fontSize: '0.9rem' }}>
            {left_desc}
            <span style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
              {left_val}
            </span>
          </p>
        </div>
        <div>
          <p style={{ fontSize: '0.9rem' }}>
            {right_desc}
            <span style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
              {right_val}
            </span>
          </p>
        </div>
      </div>
    </C.CardsMetrica>
  );
};
