import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 40px;

  @media screen and (max-width: 760px) {
    padding: 2%;
  }
`;

export const ContentLeft = styled.div`
  width: 25%;
  padding: 20px;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 6px;
`;

export const BoxInputDate = styled.div`
  margin: 20px 0;
  color: #444;

  input[type='date'] {
    width: 100%;
    margin-top: 10px;
    color: inherit;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ccc;
    background-color: #f0f0f0;
    outline: 0;
  }
`;

export const ContentRight = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 20px 40px;

  @media screen and (max-width: 760px) {
    padding: 20px 0;
  }
`;

export const HeaderContentRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: #777;
    font-size: 12px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const ContentRightForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentRightFormBoxLeft = styled.div`
  flex: 1;
  padding: 0 20px 0 0;

  @media screen and (max-width: 760px) {
    padding: 0;
  }
`;
export const ContentRightFormBoxRight = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
`;

export const BoxRegulamento = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  p {
    color: #525252;
    margin-bottom: 15px;
  }
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;

  input[type='checkbox'] {
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    cursor: pointer;
  }

  span {
    font-size: 15px;
    color: #4f4f4f;
  }
`;

export const BoxButtonSubmitModal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: 8px 20px;
    margin-left: 15px;
    background-color: #27ae60;
    border: 0;
    border-radius: 4px;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 760px) {
      align-self: flex-end;
    }
  }
`;

export const BoxButtonSubmit = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: 8px 20px;
    background-color: #27ae60;
    border: 0;
    border-radius: 4px;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 760px) {
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 760px) {
    margin-bottom: 40px;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContentModalInput = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;
