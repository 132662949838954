import { ReactElement } from 'react';

export const UF = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const Genre = ['Feminino', 'Masculino'];

export const Years = [
  '2021 / 2022',
  '2019 / 2020',
  '2017 / 2018',
  '2015 / 2016',
  '2013 / 2014',
];

export const producer_company_type = ['Produtor', 'Empresa'];

export const YesNot = ['Sim', 'Não'];

export const Satisfacao = ['Satisfeito', 'Não Satisfeito'];

export const Rusticidade = ['Boa', 'Mediana', 'Ruim'];

export const DistribuicaoSementes = ['Pneumática', 'Mecânica', 'Outro'];

export const OndeRealizou = ['TSI', 'On Farm', 'Não realizou'];

export const Sistema = ['Plantio Direto', 'Convencional'];

export const CategorieSubscribe = ['1 - Sequeiro', '2 - Irrigado'];

export const SystemProduction = ['Plantio Direto', 'Convencional', 'Outro'];

export const InfoHibrido = `
    Ao escolher um tipo de híbrido, aparece os campos ao lado para inserção (Área....). Ao salvar, essa informações se reduzem a um menu suspenso com o nome do tipo de híbrido escolhida
    Produtor deve ter opção de incluir mais de um tipo de híbrido
    O lançamento já preenchido pode ser selecionado no menu suspenso para editar, salvar ou excluir informação
`;

export const propertie = ['Fez', 'Não fez'];

export const methodPayment = ['pix', 'creditCard'];

export type LinksType = {
  title: string;
  links: Array<MenuLinks>;
};

export type MenuLinks = {
  path: string;
  name: string;
  icon?: ReactElement;
};

export const Links: Array<LinksType> = [
  {
    title: 'propriedade',
    links: [
      {
        path: '/painel',
        name: 'Painel',
      },
      {
        path: '/propriedades',
        name: 'Inscrições',
      },
      {
        path: '/usuarios',
        name: 'Usuários',
      },
    ],
  },
  {
    title: 'cadastros',
    links: [
      {
        path: '/regulamento',
        name: 'Regulamento',
      },
      // {
      //   path: "/categorias",
      //   name: "Categorias",
      // },
      {
        path: '/cupons',
        name: 'Cupons',
      },
      {
        path: '/produtoresEmpresas',
        name: 'Produtores/Empresas',
      },
      {
        path: '/rtvs',
        name: 'RTVS',
      },
      {
        path: '/hibridos',
        name: 'Híbridos',
      },
      {
        path: '/defensivos',
        name: 'Defensivos',
      },
      {
        path: '/fabricantes',
        name: 'Fabricantes de Maquinários',
      },
      {
        path: '/fabricantesFertilizantes',
        name: 'Fabricantes de Fertilizantes',
      },
    ],
  },
  {
    title: 'configurações',
    links: [
      {
        path: '/configuracoes',
        name: 'Configurações',
      },
    ],
  },
];

export const Status = ['Ativo', 'Inativo'];
export const TipoLista = [
  'Hibridos',
  'Municipios',
  'Maquinario',
  'Defensivos',
  'Fertilizantes',
];
