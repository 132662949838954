import React from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';
interface DataInfoPessoalEArea {
  data: ISubscription;
}

export const InfoManejoHistoricoArea: React.FC<DataInfoPessoalEArea> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxCulturaValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>
              Mesmo talhão inscrito no concurso anterior:{' '}
            </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.property?.is_same_previes_contest ? '1 - Sim' : '0 - Não'}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Nome do talhão inscrito: </C.BoxLabelTitle>
            <C.BoxLabelValue>{data.property?.plot_name}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Categoria inscrita: </C.BoxLabelTitle>
            <C.BoxLabelValue>{data.property?.category}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido inscrito: </C.BoxLabelTitle>
            <C.BoxLabelValue>{data.property?.hybrid.name}</C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Expectativa de produtividade: </C.BoxLabelTitle>
            <C.BoxLabelValue>{data.property?.productivity}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Custo médio: </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.property?.productivity_cost_media}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
      </C.BoxCulturaValues>
    </C.ContainerDadosPropriedade>
  );
};
