import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { BiPlus } from 'react-icons/bi';
import { DataGrid, SelectBox, TextBox } from 'devextreme-react';
import { Status } from '../../data/data';
import { Column, Button } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TCupons } from '../../types/PropertyTypes';
import api from '../../services/api';
import { toast } from 'react-toastify';

export const Cupons = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [modalDes, setModalDes] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [allCupons, setAllCupons] = useState<TCupons[]>([]);

  const [code, setCode] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [description, setDescription] = useState('');
  const [idRow, setIdRow] = useState();
  const [status, setStatus] = useState('');

  const togglePopup = () => setPopupVisibility(!isPopupVisible);
  const toggleActive = () => setModalDes(!modalDes);
  const toggleDelete = () => setModalDes(!modalDelete);
  const togglePopupEdit = () => setPopupEditVisibility(!isPopupEditVisible);

  const listCupons = useCallback(() => {
    api.get('/admin/coupom?page=1&pagesize=100').then((res) => {
      setAllCupons(res.data);
    });
  }, []);

  const createCupon = useCallback(async () => {
    console.log(code, quantity, description);
    if (code === '' || quantity === 0 || description === '') {
      return toast.error('Atenção! preencha todos os campos.');
    }

    api
      .post('/admin/coupom', {
        code,
        quantity: Number(quantity),
        description,
      })
      .then((res) => {
        if (res.status === 201) {
          setPopupVisibility(false);
          toast.success('Cupom criado!');
        }
      })
      .catch((e) => {
        toast.error(`${e.message}`);
      })
      .finally(() => listCupons());
  }, [code, quantity, description, listCupons]);

  const desativaCupom = (currentStatus: string) => {
    const msgSuccess: string =
      currentStatus === 'ACTIVE'
        ? 'Cupom desativado com sucesso!'
        : 'Cupom ativado com sucesso!';
    const msgFailure: string =
      currentStatus === 'INACTIVE'
        ? 'Error ao desativar o cupom.'
        : 'Error ao ativar o cupom.';
    api
      .patch(`/admin/coupom/${idRow}`)
      .then((res) => {
        toast.success(msgSuccess);
        listCupons();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(msgFailure);
      })
      .finally(() => {
        setModalDes(false);
      });
  };

  const removerCupom = () => {
    api
      .delete(`/adm/cupom/${idRow}`)
      .then((res) => {
        toast.success('Cupom removido com sucesso!');
        listCupons();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error('Error ao remover o cupom.');
      })
      .finally(() => {
        setModalDelete(false);
      });
  };
  const alteraQtdCupom = () => {
    api
      .put(`/admin/coupom/${idRow}`, {
        coupom_id: idRow,
        code,
        quantity: Number(quantity),
        description,
        status,
      })
      .then((res) => {
        toast.success('Cupom alterado com sucesso!');
        listCupons();
      })
      .catch((error) => {
        toast.error('Error ao alterar o cupom.');
      })
      .finally(() => {
        setPopupEditVisibility(false);
      });
  };

  const getIdCupom = (e: any) => {
    console.log(e.row.data);
    setIdRow(e.row.key);
    setStatus(e.row.data.status);
    setModalDes(true);
  };

  // const getIdCupomToDelete = (e: any) => {
  //     setIdRow(e.row.key);
  //     setModalDelete(true)
  // }

  const getCupomData = (e: any) => {
    setIdRow(e.row.key);
    setQuantity(e.row.data.quantity);
    setCode(e.row.data.code);
    setDescription(e.row.data.description);
    setPopupEditVisibility(true);
  };

  useEffect(() => {
    listCupons();
  }, [listCupons, code, quantity, description]);

  function checkStatus(data: any) {
    return data.value === 'ACTIVE' ? 'ATIVO' : 'DESATIVADO';
  }

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Cupons' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.BoxRegulamento>
              <C.BoxButtonSubmit>
                <button onClick={togglePopup}>
                  <BiPlus size={16} />
                  Cadastrar
                </button>
              </C.BoxButtonSubmit>
              <br />
              <br />
              <DataGrid
                id='cupom'
                dataSource={allCupons}
                keyExpr='id'
                showBorders={false}
                showColumnLines={false}
                columnResizingMode='nextColumn'
                allowColumnResizing={true}
                rowAlternationEnabled={true}
              >
                {/* <Column caption="Id" dataField='id' width='60' /> */}
                <Column caption='Código' dataField='code' width='80' />
                <Column caption='Qtd.' dataField='quantity' />
                <Column caption='Qtd. Usado' dataField='quantity_used' />
                <Column caption='Descrição' dataField='description' />
                <Column
                  caption='Status'
                  dataField='status'
                  width='60'
                  cellRender={checkStatus}
                />
                <Column
                  caption='AÇÕES'
                  width={100}
                  alignment='center'
                  type='buttons'
                >
                  <Button
                    name='desativar'
                    icon='preferences'
                    onClick={(e: any) => getIdCupom(e)}
                  />
                  <Button
                    name='editar'
                    icon='edit'
                    onClick={(e: any) => getCupomData(e)}
                  />
                  {/* <Button name='excluir' icon='remove' onClick={(e: any) => getIdCupomToDelete(e)} /> */}
                </Column>
              </DataGrid>
            </C.BoxRegulamento>
          </C.BoxFields>
        </C.ContentRightForm>

        <Popup
          title='Cadastro de cupons'
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopup}
          width={400}
          height={500}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Código'
                placeholder='Digite um código...'
                width={320}
                height={50}
                onValueChanged={(e) => setCode(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Quantidade'
                placeholder='Qtd.'
                width={320}
                height={50}
                onValueChanged={(e) => setQuantity(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Descrição'
                placeholder='Uma descrição...'
                width={320}
                height={50}
                onValueChanged={(e) => setDescription(e.value)}
              />
              <SelectBox
                dataSource={Status}
                stylingMode='underlined'
                labelMode='static'
                label='Status'
                placeholder='Selecione uma opção'
                width={320}
                height={50}
              />
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button onClick={createCupon}>
                <BiPlus size={18} />
                Salvar
              </button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title={`${
            status === 'INACTIVE' ? 'Ativar cupom' : 'Desativar cupom'
          }`}
          visible={modalDes}
          hideOnOutsideClick={true}
          onHiding={toggleActive}
          width={400}
          height={200}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              {status === 'INACTIVE' && (
                <p>Tem certeza que deseja ativar esse cupom?</p>
              )}
              {status === 'ACTIVE' && (
                <p>Tem certeza que deseja desativar esse cupom?</p>
              )}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setModalDes(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={() => desativaCupom(status)}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Remover cupom'
          visible={modalDelete}
          hideOnOutsideClick={true}
          onHiding={toggleDelete}
          width={400}
          height={200}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <p>Tem certdeza que deseja remover esse cupom?</p>
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setModalDelete(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={removerCupom}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>

        <Popup
          title='Edição de cupom'
          visible={isPopupEditVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopupEdit}
          width={400}
          height={500}
        >
          <C.ContentModal>
            <C.ContentModalInput>
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Código'
                placeholder='Digite um código...'
                width={320}
                height={50}
                value={code}
                onValueChanged={(e) => setCode(e.value)}
              />

              <TextBox
                readOnly={true}
                stylingMode='underlined'
                labelMode='static'
                label='Descrição'
                placeholder='Uma descrição...'
                width={320}
                height={50}
                value={description}
                onValueChanged={(e) => setDescription(e.value)}
              />
              <TextBox
                stylingMode='underlined'
                labelMode='static'
                label='Nova Quantidade'
                placeholder='Nova Qtd.'
                width={320}
                height={50}
                // type='number'
                // value={quantity}
                onValueChanged={(e) => setQuantity(e.value)}
              />
              {/* <SelectBox
                                dataSource={Status}
                                value={Status}
                                stylingMode='underlined'
                                labelMode='static'
                                label='Status'
                                placeholder='Selecione uma opção'
                                width={320}
                                height={50}
                            /> */}
            </C.ContentModalInput>
            <C.BoxButtonSubmitModal>
              <button
                onClick={() => setPopupEditVisibility(false)}
                style={{
                  background: '#e75b65',
                }}
              >
                Cancelar
              </button>
              <button onClick={alteraQtdCupom}>Confirmar</button>
            </C.BoxButtonSubmitModal>
          </C.ContentModal>
        </Popup>
      </C.ContentRight>
    </C.Container>
  );
};
