import * as C from './styles';
import 'devextreme/dist/css/dx.light.css';
import { CardInfo } from '../../components/CardInfo';
import { DataGrid } from 'devextreme-react';
import { BiHome, BiUser } from 'react-icons/bi';
import { Title } from '../../components/Title/Title';
import { Column } from 'devextreme-react/data-grid';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { CardInfoDetails } from '../../components/CardInfoDetails';

interface TDash {
  num_rtv: number;
  num_users: number;
  prods: {
    num_prod: number;
    num_prod_enterprise: number;
    total_prods: number;
  };
  producers: {
    address: string;
    city: string;
    complement: string;
    created_at: Date;
    district: string;
    document: string;
    email: string;
    id: string;
    is_enterprise: boolean;
    name: string;
    name_resp: string;
    number: string;
    phone: string;
    phone_resp: string;
    uf: string;
    updated_at: string;
    zip_code: string;
  }[];
  rtvs: {
    city: string;
    created_at: Date;
    document: string;
    email: string;
    id: string;
    name: string;
    phone: string;
    uf: string;
    updated_at: Date;
  }[];
  subs: {
    num_sub_cancel: number;
    num_sub_finish: number;
    num_sub_open: number;
    total_sub: number;
  };
  subscriptions: {
    accept_terms: boolean;
    appointment_id: string | null;
    biodefencive: number;
    coupom_id: string | null;
    coverage: number;
    created_at: Date;
    current_step: number;
    fungicide: number;
    haverst: string;
    herbicide: number;
    id: string;
    insecticide_caterpillars: number;
    insecticide_cicadas: number;
    is_coupom: boolean;
    leaf: number;
    name_creator_sub: string;
    payment_id: string | null;
    pre_sowing: number;
    producer_id: string;
    property_id: string;
    seed_treatament_id: string | null;
    sowing: number;
    specialties: number;
    status: string;
    term_id: string | null;
    tsr_id: string | null;
    updated_at: Date;
    user_id: string;
  }[];
  users: {
    address: string;
    birthday: string;
    city: string;
    complement: string;
    created_at: Date;
    district: string;
    email: string;
    gender: string;
    id: string;
    last_name: string;
    name: string;
    number: string;
    phone1: string;
    phone2: string | null;
    uf: string;
    update_pass: boolean;
    updated_at: Date;
    user_type: string;
    zip_code: string;
  }[];
}

export const Painel = () => {
  const [infoDash, setInfoDash] = useState<TDash>({} as TDash);

  // const allowDeleting = useCallback((e: any) => {
  //     return e.row.rowIndex % 2 === 1;
  // },[])

  const loadDash = useCallback(async () => {
    api.get('/admin/dash').then((res) => {
      console.log(res.data);
      setInfoDash(res.data);
    });
  }, []);

  useEffect(() => {
    loadDash();
  }, [loadDash]);

  return (
    <C.Container>
      <C.HeaderContentRight>
        <Title title='Painel' />
      </C.HeaderContentRight>

      <C.SectionCards>
        <CardInfo
          title='Inscrições realizadas'
          value={infoDash?.subs?.total_sub}
          icon={<BiHome />}
        />
        <CardInfo
          title='Usuários cadastrados'
          value={infoDash?.num_users}
          icon={<BiUser />}
        />
        <CardInfoDetails
          title='Produtores cadastrados'
          value={infoDash?.prods?.total_prods}
          icon={<BiUser />}
          left_desc='Pessoa Física:'
          left_val={infoDash?.prods?.num_prod}
          right_desc='Pessoa Jurídica:'
          right_val={infoDash?.prods?.num_prod_enterprise}
        />
        <CardInfo
          title='RTVs cadastrados'
          value={infoDash?.num_rtv}
          icon={<BiUser />}
        />
      </C.SectionCards>

      <C.SectionDoubleTable>
        <C.TableWrapper>
          <h2>Últimas incrições</h2>
          <C.TableContent>
            <DataGrid
              id='cadastrados'
              keyExpr='id'
              dataSource={infoDash.subscriptions}
              showBorders={false}
              showColumnLines={false}
              columnResizingMode='nextColumn'
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
            >
              <Column caption='Nome Usuario' dataField='name_creator_sub' />
              <Column caption='Nome da fazenda' dataField='property.name' />
              <Column caption='Nome do talhão' dataField='property.plot_name' />
              <Column caption='Cupom' dataField='coupom.code' />
              <Column caption='Status' dataField='status' />
              <Column caption='Cidade' dataField='property.city' />
              <Column caption='Estado' dataField='property.uf' />
            </DataGrid>
          </C.TableContent>
        </C.TableWrapper>
        <C.TableWrapper>
          <h2>Últimos RTVS cadastrados</h2>
          <DataGrid
            id='cupons'
            keyExpr='id'
            dataSource={infoDash.rtvs}
            showBorders={false}
            showColumnLines={false}
            columnResizingMode='nextColumn'
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
          >
            <Column caption='Nome' dataField='name' />
            <Column caption='E-mail' dataField='email' />
            <Column
              caption='Data Resgate'
              dataField='updated_at'
              dataType='date'
            />
          </DataGrid>
        </C.TableWrapper>
      </C.SectionDoubleTable>

      <C.SectionDoubleTable>
        <C.TableBottom>
          <h2>Últimos usuários</h2>
          <DataGrid
            id='ultimos-pagamentos'
            keyExpr='id'
            dataSource={infoDash?.users}
            showBorders={false}
            showColumnLines={false}
            columnResizingMode='nextColumn'
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
          >
            <Column dataField='name' caption='Nome' />
            <Column dataField='email' caption='Email' />
            <Column dataField='phone1' caption='Telefone' />
            <Column dataField='city' caption='Cidade' />
            <Column dataField='uf' caption='Estado' width={60} />
          </DataGrid>
        </C.TableBottom>
        <C.TableBottom>
          <h2>Últimos produtores</h2>
          <DataGrid
            id='ultimos-pagamentos'
            keyExpr='id'
            dataSource={infoDash?.producers}
            showBorders={false}
            showColumnLines={false}
            columnResizingMode='nextColumn'
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
          >
            <Column dataField='name' caption='Nome' />
            <Column dataField='email' caption='Email' />
            <Column dataField='document' caption='Documento' />
          </DataGrid>
        </C.TableBottom>
      </C.SectionDoubleTable>
    </C.Container>
  );
};
