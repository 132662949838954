import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid, Popup } from 'devextreme-react';
import {
  Column,
  Paging,
  SearchPanel,
  Export,
} from 'devextreme-react/data-grid';
// import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { TDataShowLastRegistered } from '../../types/PropertyTypes';
import { phoneMask, verificaAdm } from '../../helpers/helpers';
import { Position, ToolbarItem } from 'devextreme-react/autocomplete';
import { toast } from 'react-toastify';

export const Usuarios = () => {
  const [properties, setProperties] = useState<TDataShowLastRegistered[]>([]);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [idVistoria, setIdVistoria] = useState<string>('');
  // const navigate = useNavigate();

  const loadData = useCallback(async () => {
    api
      .get('/admin/users')
      .then((res) => {
        console.log(res.data);
        setProperties(res.data);
      })
      .catch((e) => console.log(e.response.data.message));
  }, []);

  const confirmaAgendamento = useCallback(async () => {
    console.log(idVistoria);
    api
      .put(`/adm/users/${idVistoria}`)
      .then((res) => {
        toast.success('Permissão alterada com sucesso!');
        setPopupVisibility(false);
        loadData();
      })
      .catch((e) => {
        toast.error(`${e.response.data.message} `);
      });
  }, [loadData, idVistoria]);

  const togglePopup = (id: string) => {
    if (!id) {
      return;
    }
    console.log(id);
    setIdVistoria(id);
    setPopupVisibility(!isPopupVisible);
  };

  function maskPhone(data: any) {
    return phoneMask(data.value);
  }

  function checkAdm(data: any) {
    return verificaAdm(data.value);
  }

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Usuários' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxRegulamento>
            <DataGrid
              id='cadastrados'
              dataSource={properties}
              keyExpr='id'
              showBorders={false}
              showColumnLines={false}
              columnResizingMode='nextColumn'
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
              onRowClick={(e) => {
                const id = e.rowElement.childNodes[0].textContent;
                console.log(id);
                togglePopup(`${id}`);
              }}
            >
              <Paging defaultPageSize={15} />
              <Export enabled={true} />
              <SearchPanel visible={true} />
              <Column caption='Nome' dataField='name' />
              <Column
                caption='Telefone'
                dataField='phone1'
                cellRender={maskPhone}
              />
              <Column caption='E-mail' dataField='email' />
              <Column caption='Cidade' dataField='city' />
              <Column caption='Estado' dataField='uf' />
              {/* <Column caption='Inscrições' dataField='qtd_insc' /> */}
              <Column
                caption='Admin'
                dataField='is_adm'
                cellRender={checkAdm}
              />
            </DataGrid>
          </C.BoxRegulamento>
        </C.ContentRightForm>
        <Popup
          title='Atenção!'
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          // onHiding={() => togglePopup(idVistoria)}
          width={340}
          height={180}
        >
          <Position at='center' my='center' />
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='before'
            render={() => (
              <button
                onClick={confirmaAgendamento}
                style={{
                  background: '#27AE60',
                  border: 'none',
                  padding: '0.5rem 1rem',
                  borderRadius: 5,
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Confirmar
              </button>
            )}
          />
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            render={() => (
              <button
                onClick={() => setPopupVisibility(false)}
                style={{
                  background: '#ff5252',
                  border: 'none',
                  padding: '0.5rem 1rem',
                  borderRadius: 5,
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Cancelar
              </button>
            )}
          />
          <p>Deseja alterar a permissão do usuários?</p>
        </Popup>
      </C.ContentRight>
    </C.Container>
  );
};
