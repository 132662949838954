import React from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';

interface DataProp {
  data: ISubscription;
}

export const DadosPropriedade: React.FC<DataProp> = ({ data }) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Nome de quem está fazendo a inscrição:{' '}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.name_creator_sub}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do produtor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.producer?.name}</C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone do produtor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.producer?.phone}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>E-mail do produtor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.producer?.email}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do RTV: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.tsrepresentative?.name}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>E-mail do RTV: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.tsrepresentative?.email}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone do RTV: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.tsrepresentative?.phone}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fazenda inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.name}</C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>Cidade da fazenda inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.city}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>UF: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.uf}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Altitude da fazenda(m): </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.height}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área total do imóvel(ha): </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.total_area}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área própria(% total): </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.own_area}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área arrendada(% total): </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.leased_area}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
