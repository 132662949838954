import React from 'react';
import * as C from './styles';
import { ISubscription } from '../../@types/types';
interface DataInfoPessoalEArea {
  data: ISubscription;
}

export const InfoManejoIdentificacaoPessoal: React.FC<DataInfoPessoalEArea> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>Área milho? </C.BoxLabelTitleSection>
      <C.BoxAlignValues>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área total de milho:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.total_area_corn}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValues>
      <C.BoxLabelTitleSection>
        Quais híbridos de milho está sendo cutivado na safra atual?{' '}
      </C.BoxLabelTitleSection>

      <C.BoxHibridosValue>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Quantidade de híbridos:</C.BoxLabelTitle>
            <C.BoxLabelValue>{data?.property?.hybrid_quantity}</C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        {data?.hybridies_subscription?.map((hybrid, index) => (
          <C.BoxAlignValues key={hybrid.id}>
            <C.BoxLabel>
              <C.BoxLabelTitle>Híbrido {index + 1}:</C.BoxLabelTitle>
              <C.BoxLabelValue>{hybrid?.hybrid?.name}</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        ))}
      </C.BoxHibridosValue>
    </C.ContainerDadosPropriedade>
  );
};
