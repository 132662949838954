import { ISubscription } from '../../@types/types';
import * as C from './styles';

type DataProp = {
  data: ISubscription;
};

export const InfoManejoCarrecaoSolo: React.FC<DataProp> = ({ data }) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>Calagem de solo </C.BoxLabelTitleSection>

      <C.BoxAlignValues>
        <C.BoxLabel>
          <C.BoxLabelTitle>Frequência [a cada x anos]:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.frequency_liming}</C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>Dose:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.property?.dosage_liming} {data?.property?.unit_liming}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValues>

      <C.BoxLabelTitleSection>Gessagem </C.BoxLabelTitleSection>

      <C.BoxAlignValues>
        <C.BoxLabel>
          <C.BoxLabelTitle>Frequência [a cada x anos]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.property?.frequency_gessagem}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Dose:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.property?.dosage_gessagem} {data?.property?.unit_gessagem}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValues>

      <C.BoxLabelTitleSection>Produção</C.BoxLabelTitleSection>

      <C.BoxAlignValuesInputsFinal>
        <C.BoxLabel>
          <C.BoxLabelTitle>Sistema de produção:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.production_system}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>População almejada:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.property?.target_population}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValuesInputsFinal>
    </C.ContainerDadosPropriedade>
  );
};
