import { useCallback, useEffect, useMemo, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid } from 'devextreme-react';
import { Column, SearchPanel, Export } from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { ISubscription } from '../../types/SystemTypes';

// import { TListSubscribes } from '../../types/PropertyTypes';

// ID
// Nome do produtor
// Estado
// Cidade
// Safra (Inverno/2023 ou verão/2023, etc)
// Método de pagamento (pix, cartão de crédito, cupom ‘qual’?)
// Previsão da colheita (data da auditoria)

export const Propriedades = () => {
  const [properties, setProperties] = useState<ISubscription[]>([]);
  const filterSelect = 'Todos';
  const navigate = useNavigate();

  console.log(properties);

  const loadData = useCallback(async () => {
    api
      .get('/admin/subscriptions')
      .then((res) => {
        setProperties(res.data);
      })
      .catch((e) => console.log(e.response.data.message));
  }, []);

  const property = useMemo(() => {
    if (properties.length === 0) {
      return [];
    }
    if (properties.length > 0 && filterSelect === 'Todos') {
      return properties;
    } else {
      return properties.filter((p) => p.safra === filterSelect);
    }
  }, [properties, filterSelect]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // function paymentMethod({ data }: any) {
  //   console.log('ASDFASDFAS', data);
  //   return data.payment === null
  //     ? data?.cupom !== null
  //       ? data?.cupom?.code
  //       : 'CADASTRO INCOMPLETO'
  //     : data.payment.type_payment.toUpperCase();
  // }

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title='Inscrições' />
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxRegulamento>
            {/* <div
              style={{
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                display: 'flex',
                marginBottom: '1rem',
              }}
            >
              <SelectBox
                items={options}
                labelMode='static'
                label='Safra'
                placeholder='Selecione um periodo'
                value={filterSelect}
                onValueChange={(e) => setFilterSelect(e)}
                width={300}
              />
            </div> */}
            <DataGrid
              id='cadastrados'
              dataSource={property}
              keyExpr='id'
              showBorders={false}
              showColumnLines={false}
              columnResizingMode='nextColumn'
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
              onRowClick={(e) =>
                navigate(
                  `/propriedades/view/${e.rowElement.childNodes[0].textContent}`
                )
              }
            >
              <Export enabled={true} />
              <SearchPanel visible={true} />
              <Column caption='Id' dataField='id' width='0' />
              <Column caption='Cod' dataField='cod' width='60' />
              <Column caption='Nome' dataField='producer.name' />
              <Column caption='Cidade' dataField='property.city' />
              <Column caption='Estado' dataField='property.uf' />
              <Column caption='Safra' dataField='haverst' />
              <Column caption='Cupom' dataField='coupom.code' />
              <Column
                caption='Previsão colheita'
                dataField='appointment.harvest_forecast'
                dataType='date'
              />
            </DataGrid>
          </C.BoxRegulamento>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
